<template>
  <v-container>
    <v-row dense>
      <v-col>
        <!-- offset is used for sticky navbar   -->
        <v-select
          v-if="calendar.length >= 6"
          @change="$vuetify.goTo('#col-' + colSelected, { offset: 80 })"
          v-model="colSelected"
          :items="returnDates"
          :label="$t('quickNav')"
          :noDataText="$t('noDataText')"
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        v-for="(milestone, index) in calendar"
        :key="index"
        :id="`col-${milestone.date.toISOString().slice(0, 10)}`"
      >
        <v-card class="indigo lighten-3">
          <v-card-title class="justify-center align-center">
            <milestone
              :key="`milestone-${milestone.milestoneSettingsId}`"
              :first-milestone="calendar[0]"
              :milestone="milestone"
              :index="index"
              :noStyle="true"
              @click="$emit('display-update', $event)"
            />
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CalendarVertical',

  components: {
    Milestone: () => import('@/components/GeneralCalendar/Milestone'),
  },

  data: () => ({
    forms: [],
    colSelected: null,
  }),

  computed: {
    ...mapGetters(['calendar']),
    returnDates() {
      return this.calendar.map(cal => ({
        text: cal.date.toLocaleDateString(navigator.language || 'fr-FR'),
        value: cal.date.toISOString().slice(0, 10),
      }))
    },
  },
}
</script>

<style lang="scss" scoped></style>
